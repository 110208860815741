import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactstrapButton from 'reactstrap/lib/Button';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { UserProfileModel } from 'client/data/models/profile/profile';
import { logger } from 'client/utils/isomorphic-logger';
import { getSaveSearchAttributes } from 'site-modules/shared/components/profile/attributes/bundles';
import { isAnonymousUserWithAppraisalData } from 'site-modules/shared/components/profile/firebase-anonymous-auth';
import {
  authenticatedSaveSearchAction,
  authenticatedAnonymousSaveAction,
} from 'client/engagement-handlers/profile-engagement-handler/profile-engagement-handler';
import { insiderMethods } from 'site-modules/shared/components/profile/insider-methods';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { getCurrentSaveSearch, getActiveSaveSearch } from './save-search-helper';

import './srp-save-search.scss';

const TOGGLE_SAVE = {
  FALSE: false,
  TRUE: true,
};

/**
 * SRP save search By visitor choice.
 */
export class SrpSaveSearchComponent extends Component {
  static getDerivedStateFromProps(nextProps) {
    return { isSaved: !!getActiveSaveSearch(nextProps) };
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    selectedFacets: PropTypes.shape({}),
    savedSearches: PropTypes.arrayOf(PropTypes.shape({})),
    searchResults: PropTypes.shape({}),
    emptyIdmData: PropTypes.bool,
    zipCode: PropTypes.string,
    removeDataFromIdm: PropTypes.func.isRequired,
    updateIdmData: PropTypes.func.isRequired,
    signInAnonymousWithToggleModalAndSave: PropTypes.func.isRequired,
    modelLinkCode: PropTypes.string,
    trackingData: PropTypes.shape({}),
    creativeId: PropTypes.string,
    CustomView: PropTypes.func,
    isAnonymousUserWithAppraisal: PropTypes.bool,
    onClick: PropTypes.func,
    showModal: PropTypes.bool,
    className: PropTypes.string,
    buttonComponent: PropTypes.elementType,
    btnColor: PropTypes.string,
  };

  static defaultProps = {
    emptyIdmData: true,
    isAuthenticated: false,
    isMobile: false,
    searchResults: {},
    selectedFacets: {},
    savedSearches: [],
    zipCode: '',
    modelLinkCode: '',
    trackingData: {},
    creativeId: 'save-search-anonymous',
    CustomView: null,
    isAnonymousUserWithAppraisal: false,
    onClick: null,
    showModal: false,
    className: '',
    buttonComponent: ReactstrapButton,
    btnColor: 'primary-b',
  };

  state = {
    isSaved: false,
    inProgress: false,
    dataToPass: {},
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isAuthenticated !== nextProps.isAuthenticated ||
      this.props.savedSearches !== nextProps.savedSearches ||
      this.props.emptyIdmData !== nextProps.emptyIdmData ||
      this.props.selectedFacets !== nextProps.selectedFacets ||
      this.props.zipCode !== nextProps.zipCode ||
      this.state.isSaved !== nextState.isSaved ||
      this.state.inProgress !== nextState.inProgress
    );
  }

  /**
   * get button tracking value
   * @example: paymenttype:loan,radius:100,sort:bestmatch:asc,zip:12345
   * @return {String}
   */
  getTrackingValue = () => {
    const { searchQuery } = getCurrentSaveSearch(this.props);
    return Object.keys(searchQuery)
      .map(key => `${key}:${searchQuery[key]}`)
      .join(',');
  };

  /**
   * Changes a state whether data is started/finished loading
   */
  toggleSave = () =>
    new Promise(resolve => {
      this.setState(prevState => ({ inProgress: !prevState.inProgress }), resolve);
    });

  /**
   * Detects if user is authenticated and starts appropriate action
   */
  handleClick = (e, additionalData) => {
    const { isSaved } = this.state;
    const { searchResults, isAuthenticated, modelLinkCode, isAnonymousUserWithAppraisal, onClick } = this.props;
    const saveSearch = isSaved ? getActiveSaveSearch(this.props) : getCurrentSaveSearch(this.props);
    let dataToPass = getSaveSearchAttributes(saveSearch, searchResults, modelLinkCode);

    if (additionalData) {
      dataToPass = { ...dataToPass, ...additionalData };
    }

    if (isAuthenticated) {
      if (isAnonymousUserWithAppraisal) {
        authenticatedAnonymousSaveAction('ANONYMOUS_UPGRADE_SAVE_SEARCH');
        return this.authenticateSave(dataToPass, TOGGLE_SAVE.FALSE);
      }
      return this.saveSearch(dataToPass, isSaved);
    } else if (onClick) {
      onClick();
    }

    return this.authenticateSave(dataToPass, TOGGLE_SAVE.TRUE);
  };

  /**
   * Sends async request with data selected to IDM
   * @param {Object} dataToPass
   * @param {Boolean} isDelete
   */
  saveSearch = (dataToPass, isDelete) =>
    this.toggleSave()
      .then(() => (isDelete ? this.props.removeDataFromIdm : this.props.updateIdmData)(dataToPass))
      .catch(() => {
        logger('warn', 'search is not saved to a storage properly');
      })
      .then(this.toggleSave);

  /**
   * Created anonymous profile and saves search then opens a authenticated save modal with the modal type of 'SAVED_SEARCH'
   * @param {Object} dataToPass
   * @param {Boolean} shouldToggleSave
   */
  authenticateSave = async (dataToPass, shouldToggleSave = true) => {
    const {
      signInAnonymousWithToggleModalAndSave,
      creativeId,
      trackingData,
      isAnonymousUserWithAppraisal,
      showModal,
      CustomView,
    } = this.props;

    if (shouldToggleSave) this.toggleSave();
    try {
      await signInAnonymousWithToggleModalAndSave(dataToPass, {
        creativeId,
        trackingData,
        showModal: showModal || !CustomView,
      });

      const searchQuery = get(dataToPass, 'savedSearches[0].searchQuery');
      if (!isAnonymousUserWithAppraisal) {
        authenticatedSaveSearchAction(searchQuery);
      }
    } catch (e) {
      logger('warn', 'vehicle is not saved to a storage properly', e);
    }
    if (shouldToggleSave) this.toggleSave();
  };

  render() {
    const { isSaved, inProgress } = this.state;
    const {
      isAuthenticated,
      emptyIdmData,
      CustomView,
      className,
      buttonComponent: ButtonComponent,
      btnColor,
    } = this.props;
    const rules = {
      'save-search-saved': isSaved,
    };
    const saveSearchValue = 'Save search';
    const trackingIdRules = {
      srp_sign_up_progress: !isAuthenticated,
      srp_unsave_search: isAuthenticated && isSaved,
      srp_save_search: isAuthenticated && !isSaved,
    };
    const trackingId = classNames(trackingIdRules);
    const trackingValue = isAuthenticated ? this.getTrackingValue() : saveSearchValue;
    const buttonValue = !isSaved ? 'Save' : saveSearchValue;
    const iconRules = {
      'icon-heart4': !isSaved,
      'icon-heart3': isSaved,
    };
    const isLoading = inProgress || (isAuthenticated && emptyIdmData);
    const isDisabledCta = isLoading || isAuthenticated === null;

    if (CustomView) {
      return (
        <CustomView
          {...this.props}
          disabled={isDisabledCta}
          isLoading={isLoading}
          onClick={this.handleClick}
          isSaved={isSaved}
          trackingId={trackingId}
          trackingValue={trackingValue}
        />
      );
    }

    return (
      <ButtonComponent
        color={btnColor}
        outline
        className={classNames(
          'srp-save-search-button usurp-save-search px-2 fw-medium srp-text-decoration-none py-0_5 pos-r',
          className,
          rules
        )}
        disabled={isDisabledCta}
        onClick={this.handleClick}
        data-tracking-id={trackingId}
        data-tracking-value={trackingValue}
        aria-label="Save Search"
      >
        <div className="btn-labels d-flex justify-content-center align-items-center">
          <i className={classNames('mr-0_25', iconRules)} />
          <span className="srp-heart-copy text-capitalize">{isSaved ? 'Saved' : buttonValue}</span>
        </div>
        {isLoading && (
          <div className="spinner-wrapper d-flex justify-content-center align-items-center bg-white w-100 p-0_5">
            <div>
              <Spinner color="primary" size={16} />
            </div>
          </div>
        )}
      </ButtonComponent>
    );
  }
}

export const stateToPropsConfig = {
  savedSearches: bindToPath(props => (!props.emptyIdmData ? 'data.savedSearches' : ''), UserProfileModel),
};

export const mapStateToProps = state => ({
  isAuthenticated: get(state, 'profile.isAuthenticated'),
  isAnonymousUserWithAppraisal: isAnonymousUserWithAppraisalData(state),
  isMobile: state.mobile,
  emptyIdmData: isEmpty(get(state, 'profile.data.idm')),
  selectedFacets: get(state, 'inventory.searchResultsFilter'),
  searchResults: get(state, 'inventory.searchResults'),
  zipCode: get(state, 'visitor.location.zipCode'),
});

export const SrpSaveSearchComponentIdmWrapper = insiderMethods(SrpSaveSearchComponent, { waitForAuth: true });

export const SrpSaveSearch = connect(mapStateToProps)(
  connectToModel(SrpSaveSearchComponentIdmWrapper, stateToPropsConfig)
);
