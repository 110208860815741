import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { connect } from 'react-redux';

import { numberWithPluralText } from 'site-modules/shared/utils/string';
import { getCorrelatorTs } from 'client/engagement-handlers/helper';
import { withSiteServedAdWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import {
  AdComponentDefaultProps,
  AdComponentPropTypes,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import {
  EasListingCreativeConfigDefaultProps,
  EasListingCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import { DEALER_CLICKOUT_CUSTOM } from 'site-modules/shared/constants/ads';
import { EAS_LISTING_AD } from 'site-modules/shared/components/native-ad/utils/constants';

import './eas-listing-usurp-ad.scss';

const CTA = 'View Dealer Offer';

const border = '1px solid #e6e6e6';
const Style = {
  imgContainer: {
    height: '200px',
    borderBottom: border,
  },
};

function AtGlanceItem({ icon, iconTitle, text }) {
  return (
    <li className="size-14 mb-0_5">
      <span title={iconTitle} className={classnames(icon, 'd-inline-block size-12 mr-0_5')} />
      {text}
    </li>
  );
}

AtGlanceItem.propTypes = {
  icon: PropTypes.node.isRequired,
  iconTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function EasListingUsurpAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig: { creativeConfigData },
  position,
  ivtClassName,
}) {
  const { cddId, ctucId, dealerName, vehicles } = creativeConfigData;
  const slider = useRef();

  function clickHandler() {
    adClickTrackingListener({
      eventTrackingId: DEALER_CLICKOUT_CUSTOM,
      customLineItemId: cddId,
      creativeConfigId: ctucId,
    });
  }

  useEffect(() => {
    adLoadTrackingListener(null, CTA, getCorrelatorTs(), cddId, { creative_config_id: ctucId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nativeStyle = EAS_LISTING_AD.NATIVE_STYLE;

  function clickDots() {
    slider.current.slickPause();
  }

  function appendDots(dots) {
    return (
      <nav>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <ul className="slick-dots" onClick={clickDots}>
          {dots}
        </ul>
      </nav>
    );
  }

  return (
    <li className={classnames('mb-0_75 mb-md-1_5 col-12 col-md-6', ivtClassName)}>
      <div
        className="eas-srp-native-usurp-ad usurp-inventory-card h-100 w-100 rounded-12 bg-white overflow-hidden"
        ref={adRef}
        data-tracking-parent={nativeStyle}
        data-viewability={`${nativeStyle}-${position}`}
      >
        {adTrackers}
        <Slider
          ref={slider}
          className="h-100"
          dots
          dotsClass="meatballs pos-a center-x bottom-0 mb-0_5"
          appendDots={appendDots}
          arrows={false}
          autoplay
          swipe
        >
          {vehicles?.map(
            ({ clickOutUrl, vehicle_image: vehicleImage, price, year, make, model, bodyStyle, trim, mileage }) => (
              <a
                key={clickOutUrl}
                href={clickOutUrl}
                target="_blank"
                className="site-served-ad-link no-focus pos-r h-100 d-flex flex-column text-decoration-none"
                rel="noopener noreferrer"
                onClick={clickHandler}
              >
                <span
                  className="pos-a ad-label text-white small px-0_5"
                  style={{ right: '8px', top: '8px', zIndex: 1 }}
                >
                  Sponsored
                </span>
                <figure className="pos-r m-0 overflow-hidden border-bottom" style={Style.imgContainer}>
                  <img src={vehicleImage} alt="" loading="lazy" className="pos-a center-xy w-100" />
                </figure>
                <div className="p-1">
                  <Row className="pb-0_75 mb-1" style={{ borderBottom: border }}>
                    <Col>
                      <h2 className="size-16 text-blue-30">
                        {year} {make} {model}
                      </h2>
                      <div className="medium">
                        {bodyStyle} {trim}
                      </div>
                    </Col>
                    {price && (
                      <Col xs={5} className="text-end heading-3" style={{ borderLeft: border }}>
                        {price.split('.')[0]}
                      </Col>
                    )}
                  </Row>
                  <ul className="list-unstyled text-cool-gray-10 mb-1_25" aria-label="At a glance">
                    {!!Number(mileage) && (
                      <AtGlanceItem
                        icon="icon-meter"
                        iconTitle="vehicle mileage"
                        text={numberWithPluralText(Number(mileage), 'mile', true)}
                      />
                    )}
                    <AtGlanceItem icon="icon-location" iconTitle="dealer name" text={dealerName} />
                  </ul>
                </div>
                <div className="px-1 mt-auto" style={{ marginBottom: '47px' }}>
                  <div
                    className={classnames(
                      'w-100 btn text-nowrap py-0_5 px-0_75 rounded-8 text-transform-none size-16 btn-blue-50 fw-medium'
                    )}
                  >
                    {CTA}
                    <span className="icon-open_in_new size-12 ml-0_25" aria-hidden />
                  </div>
                </div>
              </a>
            )
          )}
        </Slider>
      </div>
    </li>
  );
}

EasListingUsurpAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: EasListingCreativeConfigPropTypes,
};

EasListingUsurpAdUI.defaultProps = {
  creativeConfig: EasListingCreativeConfigDefaultProps,
};

const mapStateToProps = state => ({
  mobile: state.mobile,
});

const SiteServedAdWrapper = connect(mapStateToProps)(
  withSiteServedAdWrapper(siteServedAdTrackingWrapper(EasListingUsurpAdUI, true))
);

export function EasListingUsurpAd(props) {
  return <SiteServedAdWrapper {...props} adType={EAS_LISTING_AD} />;
}

EasListingUsurpAd.propTypes = {
  ...AdComponentPropTypes,
  position: PropTypes.string,
};

EasListingUsurpAd.defaultProps = {
  ...AdComponentDefaultProps,
  position: '1',
};
